import React, { useContext } from "react";
// material-ui components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import { LoginContext } from "contexts/LoginContext";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={1000} />;
});

export default function CustomModal(props) {
  const { coursePageState } = useContext(LoginContext);
  const classes = useStyles();
  const onCloseClick = () => {
    props.setModal(false)
    if (props.isReloadGrid == true) {
      props.reloadGridFunction(coursePageState.page, coursePageState.pageSize);
    }
  }
  return (
    <div>
      <Dialog
        /*  classes={{
          root: classes.center,
          paper: classes.modal,
        }} */

        disableEnforceFocus
        open={props.modal}
        transition={Transition}
        keepMounted
        onClose={props.setModal}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        maxWidth={props.maxWidth}
        fullWidth={props.fullWidth}
        fullScreen={props.fullScreen}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={props.showHeaderBackground ? classes.modalHeader + " " + classes.showhelpIcon : classes.modalHeader}
        >
          {/* {
            props.showHeaderBackground ?
            <CardIcon color="rose" style={{color:'white'}}>
              <HelpOutlineIcon />
            </CardIcon> : null
          } */}
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => onCloseClick()}
          >
            <Close className={props.showHeaderBackground ? classes.modalClose + " " + classes.closeButtonColor : classes.modalClose} />
          </Button>
          {props.hideTitle == true ? null : (
            <h4 className={props.showHeaderBackground ? classes.modalTitle + " " + classes.closeButtonColor : classes.modalTitle}>{props.title}</h4>
          )}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={
            props.padding1
              ? { height: props.height ? props.height : "inherit" }
              : { padding: 1, height: props.height ? props.height : "inherit" }
          }
        >
          {props.content}
        </DialogContent>

        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {props.showCancel ? (
            <Button
              onClick={() =>
                props.onCancelClick
                  ? props.onCancelClick()
                  : props.setModal(false)
              }
              style={{ textTransform: 'none', marginRight: 20 }}
            >
              {props.cancelButtonMessage}
            </Button>
          ) : null}
          {props.showOk ? (
            <Button
              onClick={() =>
                props.onOkClick ? props.onOkClick() : props.setModal(false)
              }
              color="success"
              style={{ textTransform: 'none' }}
            >
              {props.okButtonMessage}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
